
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































































































































































// Gallery items info: items have a fixed height (here as a percentage) and an aspect-ratio
$items-data: (
  1: (
    height: 35,
    ratio: math.div(39, 27),
  ),
  2: (
    height: 35,
    ratio: math.div(39, 27),
    margin-top: 38,
  ),
  3: (
    height: 75,
    ratio: math.div(40, 57),
    margin-top: 13,
  ),
  4: (
    height: 60,
    ratio: math.div(37, 43),
  ),
  5: (
    height: 37,
    ratio: math.div(46, 27),
  ),
  6: (
    height: 47,
    ratio: math.div(44, 36),
    // Margin is calculated to position element aligned to the bottom of 3
    margin-top: 13,
  ),
);
$absolute-items: (2, 5);

.aw-gallery {
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
  will-change: opacity;

  &.is-loaded {
    opacity: 1;
  }
}

.aw-gallery__slider {
  --gallery-height: 50rem;
  --gallery-spacing: 1rem;

  position: relative;
  height: var(--gallery-height);

  @include mq(l) {
    --gallery-height: 60rem;
  }

  @include mq(xl) {
    --gallery-height: 65rem;
  }

  @include mq(xxxl) {
    --gallery-height: 35vw;
  }
}

.aw-gallery__slider__list {
  @extend %list-nostyle;

  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: fit-content;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  counter-reset: aw-gallery;
}

.aw-gallery__slider__item {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
  height: auto;
  counter-increment: aw-gallery;

  @each $index, $item in $items-data {
    &:nth-child(#{$index}) {
      height: get-percentage(var(--gallery-height), map-get($item, height));
      aspect-ratio: map-get($item, ratio);
      margin: 0 var(--gallery-spacing);

      @if (map-get($item, margin-top)) {
        margin-top: get-percentage(
          var(--gallery-height),
          map-get($item, margin-top)
        );
      }
    }
  }

  @each $index in $absolute-items {
    // To position element easily, reduce width to 0 while keeping height at 100%
    &:nth-child(#{$index}) {
      height: var(--gallery-height);
      aspect-ratio: 0;
      margin: 0;
    }
  }
}

.aw-gallery__slider__item__picture {
  img {
    @include image-fit();

    border-radius: 1.2rem;
  }

  .show-numbers &::before {
    content: counter(aw-gallery);
    position: absolute;
    z-index: 5;
    top: 0;
    left: 1rem;
    color: $c-white;
    font-family: $ff-alt;
    font-size: 5rem;
    text-shadow: 0 0.5rem 0.5rem $c-black;
  }

  // Position element aligned on the left with previous item
  @each $index in $absolute-items {
    .aw-gallery__slider__item:nth-child(#{$index}) & {
      $item: map-get($items-data, $index);
      $previous-item: map-get($items-data, $index - 1);
      $previous-item-height: get-percentage(
        var(--gallery-height),
        map-get($previous-item, height)
      );

      position: absolute;
      /* stylelint-disable indentation */
      left: calc(
        calc(var(--gallery-spacing) * -1) -
          calc(#{$previous-item-height} * #{map-get($previous-item, ratio)})
      );
      /* stylelint-enable indentation */
      width: auto;
      height: get-percentage(var(--gallery-height), map-get($item, height));
      aspect-ratio: map-get($item, ratio);

      @if map-get($item, margin-top) {
        top: get-percentage(var(--gallery-height), map-get($item, margin-top));
      } @else {
        bottom: 0;
      }
    }
  }
}

.aw-gallery__text__inner {
  @include mq(l) {
    padding-left: col(4, 10);
  }
}
